import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
// import GoogleAds from '../components/GoogleAds'
import Layout from '../components/Layout'
import SubPageHero from '../components/Heroes/SubPageHero'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'
import Sidebar from '../components/ContentBlocks/Sidebar'
import { BottomLineGoogleAd } from '../components/styles/GoogleAdsCommon.styles'
import GoogleAdBlock from '../components/ContentBlocks/GoogleAdBlock'
import {
  BackButtonContainer,
  RegionContainer,
} from '../components/styles/Utility.styles'
import { HcpdBackArrow } from '../components/Icons'
import { linkResolver } from '../util/linkResolver'

const Subpage = ({ data }) => {
  const doc = data.prismicSubpage.data
  if (!doc) {
    return null
  }
  const suppressAds = doc.suppress_ads ? doc.suppress_ads : false
  const heroText = doc.title ? doc.title : { richText: null, text: null }
  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : null
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const displaySidebar = doc.display_sidebar ? doc.display_sidebar : false
  const sidebarData = doc.body1 ? doc.body1 : null
  const settingsNode = data.prismicGeneralSiteSettings.data
  const adBlockId = settingsNode.subpages_google_ad_tag?.document.id
  const interstitialId = settingsNode.subpages_interstitial_ad_tag?.document.id
  const uid = data.prismicSubpage.uid
  const isExcluded =
    settingsNode.excluded_documents.filter(
      item => item.excluded_document?.uid === uid
    ).length > 0
  const breadcrumbLink = doc.breadcrumb_link?.url
    ? { type: doc.breadcrumb_link.type, uid: doc.breadcrumb_link.uid }
    : null
  const breadcrumbLinkText = doc.breadcrumb_text?.text
    ? doc.breadcrumb_text.text
    : doc.breadcrumb_link?.document?.data?.link_text?.text
    ? doc.breadcrumb_link.document.data.link_text.text
    : null
  const showBreadcrumb = breadcrumbLink && breadcrumbLinkText

  // Used only when rendering sidebar
  const slices = doc.body
  const firstTwoSlices = slices.slice(0, 2)
  const remainingSlices = slices.slice(2)

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
          isExcluded: isExcluded,
        }}
      />
      <Layout renderAnchor={!suppressAds}>
        {showBreadcrumb && (
          <RegionContainer>
            <BackButtonContainer to={linkResolver(breadcrumbLink)}>
              <HcpdBackArrow />
              <span>{breadcrumbLinkText}</span>
            </BackButtonContainer>
          </RegionContainer>
        )}
        <SubPageHero heroText={heroText} />
        {displaySidebar && (
          <>
            <div className="two-column-layout" data-layout="two-column">
              <div className="two-column-layout__wrapper">
                <div className="two-column-layout__main">
                  {firstTwoSlices &&
                    firstTwoSlices.map(block => (
                      <SliceRenderer
                        key={block.id}
                        block={block}
                        suppressAds={suppressAds}
                      />
                    ))}
                </div>
                <div className="two-column-layout__aside">
                  <Sidebar sidebarData={sidebarData} />
                </div>
              </div>
            </div>
            {remainingSlices &&
              remainingSlices.map(block => (
                <SliceRenderer
                  key={block.id}
                  block={block}
                  suppressAds={suppressAds}
                />
              ))}
          </>
        )}
        {!displaySidebar && (
          <>
            {slices &&
              slices.map(block => (
                <SliceRenderer
                  key={block.id}
                  block={block}
                  suppressAds={suppressAds}
                />
              ))}
          </>
        )}
        {adBlockId && !suppressAds && <BottomLineGoogleAd id={adBlockId} />}
        {interstitialId && !suppressAds && (
          <GoogleAdBlock id={interstitialId} />
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query SubpageQuery($id: String!) {
    prismicGeneralSiteSettings {
      data {
        excluded_documents {
          excluded_document {
            uid
          }
        }
        subpages_interstitial_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        subpages_google_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
    prismicSubpage(id: { eq: $id }) {
      uid
      id
      data {
        title {
          richText
          text
        }
        meta_title {
          text
        }
        meta_description
        meta_keywords
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        suppress_ads
        display_sidebar
        breadcrumb_text {
          text
        }
        breadcrumb_link {
          id
          link_type
          slug
          type
          uid
          url
          document {
            ... on PrismicAdvanceArticle {
              data {
                link_text: header {
                  text
                }
              }
            }
            ... on PrismicAdvanceLandingPage {
              data {
                link_text: page_title {
                  text
                }
              }
            }
            ... on PrismicAudienceCampaignPage {
              data {
                link_text: page_title {
                  text
                }
              }
            }
            ... on PrismicCmeEventsLandingPage {
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicCmeMediaLibraryItem {
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicCmeMediaLibraryPage {
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicEventsLandingPage {
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicHomepage {
              data {
                link_text: header_text {
                  text
                }
              }
            }
            ... on PrismicLandingPage {
              data {
                link_text: page_title {
                  text
                }
              }
            }
            ... on PrismicMediaLibraryItem {
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicMediaLibraryPage {
              id
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicProductLandingPage {
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicStaffBoardLandingPage {
              data {
                link_text: title {
                  text
                }
              }
            }
            ... on PrismicSubpage {
              data {
                link_text: title {
                  text
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicSubpageDataBody1BodyContent {
            id
            slice_type
            slice_label
            primary {
              sidebar_header_text
              body_copy {
                richText
              }
            }
          }
          ... on PrismicSubpageDataBody1GetInTouch {
            id
            slice_label
            slice_type
            primary {
              sidebar_header_text
            }
          }
          ... on PrismicSubpageDataBody1GoogleAd {
            id
            slice_label
            slice_type
          }
        }
        body {
          ... on PrismicSubpageDataBodyAccordionBlock {
            id
            slice_type
            primary {
              section_header {
                text
              }
              bottom_padding_adjustment
            }
            items {
              content {
                richText
              }
              block_header {
                text
              }
            }
          }
          ... on PrismicSubpageDataBodyAdvancedImageBlock {
            id
            slice_type
            primary {
              layout_option
              text_content {
                richText
              }
              cta_text {
                text
              }
              cta_link {
                uid
                link_type
                id
                type
                url
              }
              header_text {
                text
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyAuthorBlock {
            id
            slice_type
            primary {
              author {
                document {
                  ... on PrismicGuestAuthor {
                    id
                    type
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    id
                    type
                    uid
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      bio {
                        richText
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyBasicImageBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              image_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyCalendarOfEventsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              layout_style
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyEventBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              event {
                id
                document {
                  ... on PrismicEvent {
                    data {
                      end_date
                      event_date
                      event_city
                      event_state
                      end_time
                      start_time
                      event_venue
                      event_address_line_1
                      event_address_line_2
                      event_image {
                        alt
                        url
                        thumbnails {
                          mobile {
                            url
                          }
                        }
                      }
                      event_title {
                        text
                      }
                      event_description {
                        text
                      }
                      event_link {
                        id
                        link_type
                        uid
                        url
                        type
                      }
                      event_link_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicSubpageDataBodyGoogleAd {
            id
            slice_type
            primary {
              google_ad_tag {
                document {
                  ... on PrismicGoogleAdTag {
                    id
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyGridBlock {
            id
            slice_type
            primary {
              layout_type
              bottom_padding_adjustment
            }
            items {
              cta_link_text
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              summary {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicSubpageDataBodyIconTextBlock {
            id
            slice_type
            primary {
              header {
                richText
              }
              body_text {
                richText
              }
              bottom_padding_adjustment
            }
            items {
              cta_text
              item_title {
                richText
              }
              item_icon {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              item_body_text {
                richText
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
            }
          }
          ... on PrismicSubpageDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyStatisticsBlock {
            id
            slice_type
            primary {
              citation
              statistic_prompt
              header {
                text
              }
              subhead {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyVideoBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_thumbnail {
                url
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              video_width
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyCustomForm {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              form_embed_code {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyRaiserSEdgeForm {
            id
            slice_type
            primary {
              raisers_edge_form_id
              form_title
              form_body {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyFormassemblyForm {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              form_url
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyCuratorSocialMediaFeed {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              curator_id
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyRiddleQuiz {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              riddle_id
              bottom_padding_adjustment
            }
          }
          ... on PrismicSubpageDataBodyTable {
            id
            slice_type
            items {
              column_1_content {
                richText
              }
              column_2_content {
                richText
              }
              column_3_content {
                richText
              }
            }
            primary {
              table_title {
                richText
                text
              }
              bottom_padding_adjustment
            }
          }
        }
      }
    }
  }
`

export default Subpage
